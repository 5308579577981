<template>
  <div class="wrap">
    <div style="position: relative">
      <a-tabs default-active-key="1" @change="tabCallback" :animated="false">
        <a-tab-pane key="1" tab="全部">
          <div v-if="cardList.length > 0">
            <div v-for="item in cardList" :key="item.id">
              <div style="display: flex; margin-bottom: 10px">
                <p style="width: 130px; color: #010101">
                  <a-checkbox
                    :indeterminate="item.indeterminate"
                    :checked="item.checkAll"
                    @change="onCheckAllChange1(item)"
                  >
                    {{ item.name }}
                  </a-checkbox>
                </p>
                <ul style="display: flex; flex-wrap: wrap">
                  <li
                    v-for="son in item.giftList"
                    :key="son.id"
                    style="width: 190px"
                  >
                    <a-checkbox v-model="son.checked">
                      {{ son.name
                      }}<span v-if="son.code">({{ son.code }})</span>
                    </a-checkbox>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="优加惠品" force-render>
          <div v-if="cardList.length > 0">
            <div v-for="item in cardList" :key="item.id">
              <div style="display: flex; margin-bottom: 10px">
                <p style="width: 160px; color: #010101">
                  <a-checkbox
                    :indeterminate="item.indeterminate"
                    :checked="item.checkAll"
                    @change="onCheckAllChange1(item)"
                  >
                    {{ item.name }}
                  </a-checkbox>
                </p>
                <ul style="display: flex; flex-wrap: wrap">
                  <li
                    v-for="son in item.giftList"
                    :key="son.id"
                    style="width: 190px"
                  >
                    <a-checkbox v-model="son.checked">
                      {{ son.name
                      }}<span v-if="son.code">({{ son.code }})</span>
                    </a-checkbox>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" tab="好乐滋">
          <div v-if="cardList.length > 0">
            <div v-for="item in cardList" :key="item.id">
              <div style="display: flex; margin-bottom: 10px">
                <p style="width: 120px; color: #010101">
                  <a-checkbox
                    :indeterminate="item.indeterminate"
                    :checked="item.checkAll"
                    @change="onCheckAllChange1(item)"
                  >
                    {{ item.name }}
                  </a-checkbox>
                </p>
                <ul style="display: flex; flex-wrap: wrap">
                  <li
                    v-for="son in item.giftList"
                    :key="son.id"
                    style="width: 190px"
                  >
                    <a-checkbox v-model="son.checked">
                      {{ son.name }}
                      <span v-if="son.code">({{ son.code }})</span>
                    </a-checkbox>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>

      <div style="display: flex;align-items: center;">
        <div style="margin: 10px 0">
          <span style="color: rgba(0, 0, 0); line-height: 30px">创建时间:</span>
          <a-range-picker
          :allowClear="false"
            :ranges="ranges"
            @change="dateChange"
            :default-value="[
              moment('2024-03-05', dateFormat),
              moment('2024-03-05', dateFormat),
            ]"
            :format="dateFormat"
            style="width: 300px; margin: 0 10px"
          />
        </div>
        <div v-if="params.statisticsType == 1">
          <span style="color: rgba(0, 0, 0); line-height: 30px">当前客户经理: </span>
          <a-select
            allowClear
            v-model="params.staffIdListParam"
            show-search
            mode="multiple"
            :filter-option="
              (input, option) => {
                return (
                  option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }
            "
            style="width: 300px"
            placeholder="请选择客户经理"
            :default-value="null"
            option-label-prop="label"
          >
            <a-select-option
              :value="item.id"
              :label="item.name"
              v-for="(item, index) in userData"
              :key="index"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        
        </div>
        <a-button
            type="primary"
            style="width: 100px; color: #fff;margin-left: 10px;"
            @click="getList()"
            >查询</a-button
          >
      </div>
    </div>

    <a-tabs default-active-key="1" @change="callback" :animated="false">
      <a-tab-pane key="1" tab="客户维度">
        <a-table
          :pagination="false"
          :columns="columnsCustomer"
          :scroll="{ x: 1500 }"
          :loading="tabLoading"
          :data-source="dataSource"
          bordered
        >
          <span slot="cardSizeCount">
            销售张数<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.cardSizeCount
              )
            }}
          </span>

          <span slot="amountCount">
            实收金额<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.amountCount
              )
            }}
          </span>

          <span slot="repayCount">
            回款金额<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.repayCount
              )
            }}
          </span>
          <span slot="quotaCount">
            充值金额<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.quotaCount
              )
            }}
          </span>
          <span slot="balanceCount">
            余额<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.balanceCount
              )
            }}
          </span>
          <span slot="amountOnLineCount">
            线上金额<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.amountOnLineCount
              )
            }}
          </span>
          <span slot="amountOffLineCount">
            线下金额<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.amountOffLineCount
              )
            }}
          </span>
        </a-table>
      </a-tab-pane>

      <a-tab-pane key="2" tab="卡券维度" force-render>
        <a-table
          :pagination="false"
          :columns="columns"
          :scroll="{ x: 1500 }"
          :loading="tabLoading"
          :data-source="dataSource"
          bordered
        >
          <span slot="cardSizeCount">
            销售张数<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.cardSizeCount
              )
            }}
          </span>

          <span slot="amountCount">
            实收金额<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.amountCount
              )
            }}
          </span>

          <span slot="repayCount">
            回款金额<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.repayCount
              )
            }}
          </span>
          <span slot="quotaCount">
            充值金额<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.quotaCount
              )
            }}
          </span>
          <span slot="balanceCount">
            余额<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.balanceCount
              )
            }}
          </span>
          <span slot="amountOnLineCount">
            线上金额<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.amountOnLineCount
              )
            }}
          </span>
          <span slot="amountOffLineCount">
            线下金额<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.amountOffLineCount
              )
            }}
          </span>
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="3" tab="客户经理维度">
        <a-table
          :pagination="false"
          :columns="columns"
          :scroll="{ x: 1500 }"
          :loading="tabLoading"
          :data-source="dataSource"
          bordered
        >
          <span slot="cardSizeCount">
            销售张数<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.cardSizeCount
              )
            }}
          </span>

          <span slot="amountCount">
            实收金额<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.amountCount
              )
            }}
          </span>

          <span slot="repayCount">
            回款金额<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.repayCount
              )
            }}
          </span>
          <span slot="quotaCount">
            充值金额<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.quotaCount
              )
            }}
          </span>
          <span slot="balanceCount">
            余额<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.balanceCount
              )
            }}
          </span>
          <span slot="amountOnLineCount">
            线上金额<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.amountOnLineCount
              )
            }}
          </span>
          <span slot="amountOffLineCount">
            线下金额<br />
            合计：{{
              new Intl.NumberFormat("en-US", { style: "decimal" }).format(
                cardOut.amountOffLineCount
              )
            }}
          </span>
        </a-table>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import { request } from "@/utils/request";
import moment from "moment";
import "moment/locale/zh-cn";
export default {
  data() {
    return {
      dateFormat: "YYYY-MM-DD",

      checkedList: [],
      indeterminate: true,
      checkAll: false,

      tabLoading: false,

      queryData: {},
      userData: [],
      ranges: {
        今天: [moment(), moment()],
        昨天: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        当月: [moment().startOf("month"), moment().endOf("month")],
        上月: [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
      },
      cardList: [],
      columns: [
        {
          title: "序号",
          customRender: (text, record, index) => index + 1,
          align: "center",
        },
        {
          title: "名称",
          dataIndex: "name",
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            if (row.no == row.no) {
              console.log(row, "row");
              console.log(index, "index");
              obj.attrs.rowSpan = 0;
              if (row.index == 1) {
                obj.attrs.rowSpan = row.indexSize;
              }
            }
            return obj;
          },
          align: "center",
        },
        {
          title: "卡类型",
          dataIndex: "giftType",
          align: "center",
        },
        {
          dataIndex: "size",
          slots: { title: "cardSizeCount" },
          align: "center",
        },
        // 实收
        {
          dataIndex: "amount",
          slots: { title: "amountCount" },
          align: "center",
          colSpan: 2,
          // sorter: true,
          customSort: (a, b) => a.amount - b.amount, // 倒序排序
          // sortDirections: ['descend'] // 设置为从大到小排序
        },
        // 实收合计
        {
          dataIndex: "amountTotal",
          align: "center",
          colSpan: 0,
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            if (row.no == row.no) {
              console.log(index, "index");
              obj.attrs.rowSpan = 0;
              if (row.index == 1) {
                obj.attrs.rowSpan = row.indexSize;
              }
            }
            return obj;
          },
        },
        // 回款金额
        {
          dataIndex: "repay",
          colSpan: 2,
          slots: { title: "repayCount" },
          customSort: (a, b) => a.amount - b.amount, // 倒序排序
          align: "center",
        },
        // 回款合计
        {
          dataIndex: "repayTotal",
          colSpan: 0,
          // slots: { title: 'repayTotal' },
          align: "center",
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            if (row.no == row.no) {
              console.log(row, "row");
              console.log(index, "index");
              obj.attrs.rowSpan = 0;
              if (row.index == 1) {
                obj.attrs.rowSpan = row.indexSize;
              }
            }
            return obj;
          },
        },
        {
          dataIndex: "quota",
          slots: { title: "quotaCount" },
          align: "center",
        },
        {
          dataIndex: "balance",
          slots: { title: "balanceCount" },
          align: "center",
        },
        {
          dataIndex: "amountOnLine",
          slots: { title: "amountOnLineCount" },
          align: "center",
        },
        {
          dataIndex: "amountOffLine",
          slots: { title: "amountOffLineCount" },
          align: "center",
        },
        {
          title: "平均单价",
          dataIndex: "amountAvg",
          align: "center",
        },
        {
          title: "平均折扣",
          dataIndex: "discount",
          align: "center",
        },
      ],
      columnsCustomer: [
        {
          title: "序号",
          customRender: (text, record, index) => index + 1,
          align: "center",
        },
        {
          title: "名称",
          dataIndex: "name",
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            if (row.no == row.no) {
              console.log(row, "row");
              console.log(index, "index");
              obj.attrs.rowSpan = 0;
              if (row.index == 1) {
                obj.attrs.rowSpan = row.indexSize;
              }
            }
            return obj;
          },
          align: "center",
        },
        {
          title: "卡类型",
          dataIndex: "giftType",
          align: "center",
        },
        {
          dataIndex: "size",
          slots: { title: "cardSizeCount" },
          align: "center",
        },
        {
          title: "出库客户经理",
          dataIndex: "outStaffName",
          slots: { title: "outStaffName" },
          align: "center",
        },
        {
          title: "当前客户经理",
          dataIndex: "staffName",
          slots: { title: "staffName" },
          align: "center",
        },
        // 实收
        {
          dataIndex: "amount",
          slots: { title: "amountCount" },
          align: "center",
          colSpan: 2,
          customSort: (a, b) => a.amount - b.amount, // 倒序排序
        },
        // 实收合计
        {
          dataIndex: "amountTotal",
          align: "center",
          colSpan: 0,
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            if (row.no == row.no) {
              console.log(index, "index");
              obj.attrs.rowSpan = 0;
              if (row.index == 1) {
                obj.attrs.rowSpan = row.indexSize;
              }
            }
            return obj;
          },
        },
        // 回款金额
        {
          dataIndex: "repay",
          colSpan: 2,
          slots: { title: "repayCount" },
          customSort: (a, b) => a.amount - b.amount, // 倒序排序
          align: "center",
        },
        // 回款合计
        {
          dataIndex: "repayTotal",
          colSpan: 0,
          // slots: { title: 'repayTotal' },
          align: "center",
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            if (row.no == row.no) {
              console.log(row, "row");
              console.log(index, "index");
              obj.attrs.rowSpan = 0;
              if (row.index == 1) {
                obj.attrs.rowSpan = row.indexSize;
              }
            }
            return obj;
          },
        },
        {
          dataIndex: "quota",
          slots: { title: "quotaCount" },
          align: "center",
        },
        {
          dataIndex: "balance",
          slots: { title: "balanceCount" },
          align: "center",
        },
        {
          dataIndex: "amountOnLine",
          slots: { title: "amountOnLineCount" },
          align: "center",
        },
        {
          dataIndex: "amountOffLine",
          slots: { title: "amountOffLineCount" },
          align: "center",
        },
        {
          title: "平均单价",
          dataIndex: "amountAvg",
          align: "center",
        },
        {
          title: "平均折扣",
          dataIndex: "discount",
          align: "center",
        },
      ],
      dataSource: [],
      params: {
        distributorCode: "",
        dateMin: new Date().toISOString().slice(0, 10),
        dateMax: new Date().toISOString().slice(0, 10),
        giftIdList: [],
        statisticsType: 1,
        ordersKaTypeList: [],
        staffIdList: [],
        // staffIdListParam:[]
      },
      cardOut: null,
    };
  },
  mounted() {
    this.getCardList();
    // 客户经理
    this.getUserList();
  },
  methods: {
    findDuplicateIds(array) {
      const idMap = {};
      const duplicates = [];
      array.forEach((item, index) => {
        if (idMap[item.id] === undefined) {
          idMap[item.id] = index;
        } else {
          if (!duplicates.some((duplicate) => duplicate.id === item.id)) {
            duplicates.push({ id: item.id, indexes: [idMap[item.id], index] });
          } else {
            const existingDuplicate = duplicates.find(
              (duplicate) => duplicate.id === item.id
            );
            existingDuplicate.indexes.push(index);
          }
        }
      });
      return duplicates;
    },
    moment,
    onCheckAllChange1(card) {
      // 货堆/宅配
      if (card.id == 2021) {
        card.checkAll = !card.checkAll;
        card.indeterminate = false;
        card.giftList.forEach((son) => {
          son.checked = card.checkAll;
        });
        // 检查子项的选中状态
        const allGiftsChecked = card.ordersKaTypeList.every(
          (son) => son.checked
        );
        if (allGiftsChecked) {
          card.checkAll = true;
        } else {
          const someGiftsChecked = card.giftList.some((son) => son.checked);
          card.indeterminate = someGiftsChecked;
        }
      } else {
        card.checkAll = !card.checkAll;
        card.indeterminate = false;
        card.giftList.forEach((son) => {
          son.checked = card.checkAll;
        });
        // 检查子项的选中状态
        const allGiftsChecked = card.giftList.every((son) => son.checked);
        if (allGiftsChecked) {
          card.checkAll = true;
        } else {
          const someGiftsChecked = card.giftList.some((son) => son.checked);
          card.indeterminate = someGiftsChecked;
        }
      }
    },
    // 查询列表
    getList() {
      this.params.giftIdList = [];
      this.params.ordersKaTypeList = [];
      this.cardList.forEach((item) => {
        item.giftList.forEach((son) => {
          if (son.checked) {
            if (item.id == 2021) {
              this.params.ordersKaTypeList.push(son.id);
            } else {
              this.params.giftIdList.push(son.id);
            }
          }
        });
      });
      this.tabLoading = true;
      request(
        process.env.VUE_APP_API_BASE_URL + "sfa/chart/card/out/statistics",
        "post",
        this.params
      ).then((res) => {
        this.tabLoading = false;
        if (res.data.code == 200) {
          this.cardOut = res.data.data;
          this.dataSource = res.data.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 卡类型数据
    getCardList() {
      request(
        process.env.VUE_APP_API_BASE_URL + "sfa/card/gift/type/list/gift",
        "get"
      ).then((res) => {
        if (res.data.code == 200) {
          this.cardList = res.data.data;
          this.cardList.forEach((item) => {
            item.indeterminate = false;
            item.checkAll = true;
            item.giftList.forEach((son) => {
              this.$set(son, "checked", true);
            });
          });
          // 查列表
          this.getList();
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 获取客户经理
    getUserList() {
      request(process.env.VUE_APP_API_BASE_URL + "sfa/staff/list/1", "get").then(
        (res) => {
          if (res.data.code == 200) {
            this.userData = res.data.data;
          } else {
            this.$message.error(res.data.message);
          }
        }
      );
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    callback(key) {
      this.params.statisticsType = key;
      this.getList();
    },
    tabCallback(key) {
      switch (key) {
        case "1":
          this.params.distributorCode = "";
          break;
        case "2":
          this.params.distributorCode = "20220601001";
          break;
        case "3":
          this.params.distributorCode = "20221212001";
          break;
      }
      request(
        process.env.VUE_APP_API_BASE_URL +
          "sfa/card/gift/type/list/gift" +
          "/" +
          this.params.distributorCode,
        "get"
      ).then((res) => {
        if (res.data.code == 200) {
          this.cardList = res.data.data;
          this.cardList.forEach((item) => {
            item.indeterminate = false;
            item.checkAll = true;
            item.giftList.forEach((son) => {
              this.$set(son, "checked", true);
              this.params.giftIdList.push(son.id);
            });
          });
          // 查列表
          this.getList();
        } else {
          this.$message.error(res.data.message);
        }
      });
      // this.getList();
    },
    // 选择日期
    dateChange(date, dateString) {
      this.params.dateMin = dateString[0].toString();
      this.params.dateMax = dateString[1].toString();
    },
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
h1,
h2,
p,
a,
button,
input,
select,
textarea {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  font-family: inherit;
  text-decoration: none;
}

div .ant-tag-blue {
  color: #5542f6 !important;
  background: none !important;
  border: 1px solid #5542f6 !important;
}

ul {
  list-style: none;
}

.wrap {
  padding: 0 20px;
  box-sizing: border-box;
}
</style>
